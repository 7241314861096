import {
    Box,
    Button,
    Grid,
    makeStyles,
    Paper,
    Typography,
    IconButton,
    Checkbox,
  } from "@material-ui/core";
  import instance from "api/globalaxios";
  import Axios from "axios";
  import { format } from "date-fns";
  import CircularCenteredLoader from "features/common/CircularCenteredLoader";
  import debounce from "lodash.debounce";
  import React, { useCallback, useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  import { useHistory } from "react-router-dom";
  import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
  import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
  import { isMuthoot } from "Routes/Routes";
import Tabs from "features/Tabs/Tabs";
import { Add } from "@material-ui/icons";
import "react-nice-dates/build/style.css";
import { ARBITRATOR, MEDIATOR } from "app/constants";
import SearchBar from "Components/SearchBar";
import Table from "Components/Table/Table";
// import DisputeRowMuthootUser from "./DisputeRowMuthootUser";
  
  export async function changeDisputeState(disputeId, state) {
    return await instance.get(
      `/changedisputestatehotstar/${disputeId}/${state}/`
    );
  }
  
  export async function deleteDisputeapi(disputeId) {
    return await instance.delete(`/deletedisputehotstar/${disputeId}/`);
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "3rem",
      [theme.breakpoints.down("sm")]: {
        padding: "1.5rem",
      },
    },
    sidebar: {
      padding: "1rem",
      userSelect: "none",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    content: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "3rem",
      },
    },
    bigheading: {
      color: isMuthoot ? 'black' : "#4F4F4F",
      // fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "36px",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    typeButton: {
      textTransform: "none",
      border: "1px solid #4F4F4F",
      borderRadius: "0",
      padding: "0 1.5rem",
      color: "#4F4F4F",
      backgroundColor: 'white',
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      height: "42px",
  
      [theme.breakpoints.down("xs")]: {
        height: "34px",
        fontSize: "14px",
      },
    },
    typeButtonBlue: {
      textTransform: "none",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      background: isMuthoot ? theme.palette.primary.main : "#2F80ED",
      borderRadius: "0",
      padding: "0 1.5rem",
      color: "#fff",
      // fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      height: "42px",
      "&:hover": {
        background: "#FFFFFF",
        color: isMuthoot ? theme.palette.primary.main : "#2F80ED",
        border: "1px solid",
        borderColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        height: "34px",
        fontSize: "14px",
      },
    },
    typechoices: {
      color: "#333333",
      fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      textTransform: "capitalize",
      paddingRight: "5px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    typeFormControl: {
      margin: 0,
      "&:hover": {
        background: "#E0E0E0",
      },
    },
    spaceBox: {
      height: "4rem",
      [theme.breakpoints.down("sm")]: {
        height: "3rem",
      },
      [theme.breakpoints.down("xs")]: {
        height: "2rem",
      },
    },
    griditem: {
      padding: "8px",
      [theme.breakpoints.down("xs")]: {
        padding: "4px",
      },
    },
    title: { 
      fontFamily: 'Satoshi-Bold',
      fontSize: "14px",
      lineHeight: "17px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
      },
    },
    value: {
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      "&:hover": {
        background: "#E0E0E0",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
      },
    },
    titlePaper: {
      padding: "1.5rem",
      boxShadow: "none",
      width: "100%",
      backgroundColor: "initial",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    valuePaper: {
      padding: "1.5rem",
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      border: "1px solid #E0E0E0",
      "&:hover": {
        background: "#E0E0E0",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    menuwidth: {
      width: "200px",
      [theme.breakpoints.down("sm")]: {
        width: "150px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "130px",
      },
    },
    alginCenter: {
      textAlign: "center",
    },
    tabsContainer: {
      border: '1px solid black',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        flexWrap: 'wrap',
      },
    },
    selected: {
      padding: "9.8px 14.7px",
      cursor: 'pointer',
      fontSize: '14.7px',
      fontFamily: 'Satoshi-Medium',
      minWidth: 'fit-content',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    unselected: {
      fontSize: '14.7px',
      padding: "9.8px 14.7px",
      cursor: "pointer",
      fontFamily: 'Satoshi-Medium',
      minWidth: 'fit-content',
      textAlign: 'center',
    },
  }));
  
  export function isValidMonth(value) {
    if (
      value == "january" ||
      value == "february" ||
      value == "march" ||
      value == "april" ||
      value == "may" ||
      value == "june" ||
      value == "july" ||
      value == "august" ||
      value == "september" ||
      value == "october" ||
      value == "november" ||
      value == "december"
    ) {
      return true;
    }
    return false;
  }
  export function isValidNumber(value) {
    return !isNaN(value);
  }
  export function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  export function calc_days(date1, date2 = null) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const dateObj1 = new Date(date1);
    const dateObj2 = date2 ? new Date(date2) : new Date();
    const timeDifference = dateObj2 - dateObj1;
    const dayDifference = Math.round(timeDifference / ONE_DAY);
    return dayDifference;
  }

  function isValidOrixId(input) {
    const regex = /\b(GRC|GRO)-\d+-\d{4}\b/i;
    return regex.test(input);
  }
  
  export default function DisputesOrixUser({
    disputesLoading,
    setType,
    type,
    disputes,
    reload,
    profile,
  }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
  
    const [searchText, setSearchText] = useState("");
    const [disputesBySearch, setDisputesBySearch] = useState(disputes);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [cancelToken, setCancelToken] = useState();
  
    const debouceSearch = useCallback(debounce(search, 500), [disputes]);

    const processedData = (data) => {
      const result = data.reduce((arr, item) => {
        const transformedObject = { 
          ticketNumber: item.gro_ticket_number ? item.gro_ticket_number : item.grc_ticket_number, 
          complainDetails: <Typography className="lineClamp">{item?.description}</Typography>, 
          timeStamp: item.reopen_date 
            ? format(new Date(item.reopen_date), "dd MMMM, y, h:mm a") 
            : item.submission_date 
              ? format(new Date(item.submission_date), "dd MMMM, y, h:mm a") 
              : '-',
          ...item,
        };
        arr.push(transformedObject);
        return arr;
      }, []);
      
      return result;
    };
  
    useEffect(() => {
      setDisputesBySearch(processedData(disputes));
    }, [disputes]);
  
    function search(value, type) {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
  
      if (value.trim() != "") {
        let ar = value.trim().split("-");
        let date = new Date(ar[1] + "/" + ar[0] + "/" + ar[2]);
        if (isValidDate(date)) {
          dispatch(getOrixdatabydate(date, x, type));
          setLoadingSearch(false);
        } else if (isValidNumber(value.trim()) || isValidOrixId(value.trim())) {
          dispatch(getOrixdatabyDisputeId(value.trim(), x, type));
          setLoadingSearch(false);
        } 
      } else {
        setDisputesBySearch([...processedData(disputes)]);
        setLoadingSearch(true);
      }
    }

    function getOrixdatabydate(date, cancelToken, type=null, region=null) {
      return async (dispatch) => {
        try {
          const response = await instance.get(
            `api/orixsearchbydateView/${format(
              date,
              "dd-MM-yyyy"
            )}/?type=${type}&region=${region}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(processedData(response.data));
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }

    function getOrixdatabyDisputeId(value, cancelToken, type=null, region=null, days=null) {
      return async (dispatch) => {
        try {
          const response = await instance.get(
            `/api/orixsearchbydisputeidView/${value}/?type=${type}&region=${region}&days=${days}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(processedData(response.data));
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }

    const tabs = [
      {
        id: "open",
        label: "In progress",
      },
      {
        id: "closed",
        label: "Closed",
      },
    ];

    const [currentTab, setCurrentTab] = useState(type);

    const handleTabChange = (newValue) => {
      setCurrentTab(newValue);
      setType(newValue);
      setSearchText("");
      sessionStorage.setItem("orix-type", newValue);
    };

    const columns = [
      { field: 'ticketNumber', headerName: 'Ticket Number', width: '16.66%'},
      { field: 'complainDetails', headerName: 'COMPLAINT DETAILS', width: '66.64%'},
      { field: 'timeStamp', headerName: 'Date & Time', width: '16.66 %'},
    ];

    const onRowClick = (row) => {
      history.push(`/disputes/${row.id}`);
    };
  
    return (
      <>
        <Grid container className={classes.root}>
          <Box className={classes.spaceBox} />
          {disputesLoading ? (
            <CircularCenteredLoader />
          ) : (
            <>
              <Grid container>
                <Grid
                  item
                  className={classes.griditem}
                  style={{ paddingLeft: 0, flex: 1, }}
                >
                  <SearchBar
                    placeholder="Search by Date (DD-MM-YYYY) or Ticket Number"
                    value={searchText}
                    onChange={(value) => {
                      setSearchText(value);
                      debouceSearch(value, type);
                    }}
                    backgroundColor="#f5f5f5"
                    height={42}
                    borderRadius={0}
                    showSearchIcon={true}
                    TextFieldProps={{
                      fullWidth: true,
                    }}
                    // hoverBackgroundColor="#e0e0e0"
                    // textColor="#333"
                    // iconColor="#666"
                    // fontSize={'1rem'}
                    // borderColor="#ccc"
                    // hoverBorderColor="#999"
                    // focusBorderColor="#2196f3"
                  />
                </Grid>
                <Grid item className={classes.griditem}>
                  <Tabs
                    tabs={tabs}
                    handleClickTab={handleTabChange}
                    choice={currentTab}
                    tabsClasses={{
                      selected: classes.selected,
                      unselected: classes.unselected,
                    }}
                    tabsContainerClass={classes.tabsContainer}
                  />
                </Grid>
                <Grid item className={classes.griditem}>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Add/>}
                      onClick={() => {
                        history.push("/intro");
                      }}
                      style={{fontSize:'14px',padding:'9px 14.7px'}}
                    >
                      FILE NEW
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {/* Disputes list starts from here */}
              <Box className={classes.spaceBox}></Box>
              <Table
                data={disputesBySearch}
                columns={columns}
                onRowClick={onRowClick}
                loading={disputesLoading}
                customStyles={{ 
                  tableStyle: { border: '1px solid #e0e0e0' },
                  rowStyle: { backgroundColor: 'transparent' }, 
                  cellStyle: { fontFamily: 'Satoshi-Bold' } 
                }}
              />
            </>
          )}
        </Grid>
      </>
    );
  }
  