import { createSlice } from "@reduxjs/toolkit";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import fileDownload from "js-file-download";
import {
  isArb,
  isCDR,
  isDifc,
  isDigipub,
  isHotStar,
  isKilumaya,
  isMSME,
  isMuthoot,
  isNbf,
  isNSE,
  isOrix,
  isRv,
  isSebi,
  isStar,
  isTdsat,
  isTiac,
  isVikalp,
  isWebnyay,
} from "Routes/Routes";

export const initialState = {
  loading: false,
  hasErrors: false,
  disputes: [],
  disputesall: [],
  activeDispute: {},
  respondent: { active: [], resolved: [] },
  claimant: { active: [], resolved: [] },
  userFiles: [],
  recordings: [],
};

const disputeSlice = createSlice({
  name: "disputes",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    stopProgress: (state) => {
      state.loading = false;
    },
    setSucessfullAll: (state, { payload }) => {
      state.disputesall = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setSucessfull: (state, { payload }) => {
      state.disputes = payload.results;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    setSucessfullCreate: (state, { payload }) => {
      state.disputes.push(payload);
      state.activeDispute = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setSucessfullGet: (state, { payload }) => {
      const index = state.disputes.findIndex((disp) => disp.id === payload.id);
      if (index !== -1) {
        state.disputes[index] = payload;
      }
      state.activeDispute = payload;

      state.loading = false;
      state.hasErrors = false;
    },
    setRespondentAndClaimant: (state, { payload }) => {
      state.respondent = payload.respondent;
      state.claimant = payload.claimant;
    },
    setUserFiles: (state, { payload }) => {
      console.log("in payload ", payload);
      state.userFiles = payload;
    },
    setRecordings: (state, { payload }) => {
      state.recordings = payload;
    },
  },
});

export const {
  setProgress,
  setSucessfull,
  setSucessfullAll,
  setError,
  setSucessfullCreate,
  setSucessfullGet,
  setRespondentAndClaimant,
  setUserFiles,
  setRecordings,
} = disputeSlice.actions;

export default disputeSlice.reducer;

export function getRealhost() {
  if (isCDR) {
    return "cdr";
  } else if (isSebi) {
    return "sebi";
  } else if (isMSME) {
    return "msme";
  } else if (isDigipub) {
    return "digipub";
  } else if (isNbf) {
    return "nbf";
  } else if (isVikalp) {
    return "vikalp";
  } else if (isTiac) {
    return "tiac";
  } else if (isArb) {
    return "arb";
  } else if (isKilumaya) {
    return "kilumaya";
  } else if (isDifc) {
    return "difc";
  } else if (isNSE) {
    return "nse";
  } else if (isHotStar) {
    return "hotstar";
  } else if (isStar) {
    return "star";
  } else if (isMuthoot) {
    return "muthoot";
  } else if (isRv) {
    return "rv";
  } else if (isWebnyay) {
    return "webnyay";
  }else if (isTdsat) {
    return "tdsat";
  }else if (isOrix) {
    return "orix";
  }
  return "";
}

export function gethost() {
  let host = " ";
  if (isCDR) {
    host = "cdr";
  } else if (isRv) {
    host = "rv";
  } else if (isDigipub) {
    host = "digipub";
  } else if (isNbf) {
    host = "nbf";
  } else if (isHotStar) {
    host = "hotstar";
  } else if (isStar) {
    host = "star";
  } else if (isMuthoot) {
    host = "muthoot";
  } else if (isWebnyay) {
    host = "webnyay";
  } else if (isNSE) {
    host = "nse";
  } else if (isVikalp) {
    host = "vikalp";
  }else if (isTdsat) {
    return "tdsat";
  }else if (isOrix) {
    return "orix";
  }
  return host;
}

export function getSplitBy() {
  if (isSebi || isMSME) return "v.";
  else if (isHotStar || isStar || isMuthoot) return "&";
  return "vs";
}

export function getDisputesAll(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: "/api/disputeall/" + "?host=" + gethost(),
      params: params,
    }).then(
      (response) => {
        dispatch(setSucessfullAll(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function CloseLevelOneGrievance(disputeId) {
  console.log("disputeId fun", disputeId);
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "put",
        url: `/level1updatedispute/${disputeId}/`,
      });
      console.log("response.data", response.data);
      // dispatch(setSucessfull(response.data));
    } catch (error) {
      console.log("error.request.status", error.request.status);
      console.log("error.message", error.message);
      // console.log(error.request.response);
    }
  };
}

export function CloseLeveltwoGrievance(disputeId) {
  console.log("disputeId fun", disputeId);
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "put",
        url: `/closedisputeatleveltwo/${disputeId}/?host=${gethost()}`,
      });
      console.log("response.data", response.data);
      // dispatch(setSucessfull(response.data));
    } catch (error) {
      console.log("error.request.status", error.request.status);
      console.log("error.message", error.message);
      // console.log(error.request.response);
    }
  };
}

export function MoveDisputeToLevelTwo(disputeId) {
  console.log("disputeId fun", disputeId);
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "put",
        url: `/movedisputeleveltwo/${disputeId}/`,
      });
      console.log("response.data", response.data);
      return response.data;
      // dispatch(setSucessfull(response.data));
    } catch (error) {
      console.log("error.request.status", error.request.status);
      console.log("error.message", error.message);
      // console.log(error.request.response);
    }
  };
}

export function CloseDisputeAtLevelOne(disputeId) {
  console.log("disputeId fun", disputeId);
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "put",
        url: `/closedisputeatlevelone/${disputeId}/`,
      });
      console.log("response.data", response.data);
      return response.data;
      // dispatch(setSucessfull(response.data));
    } catch (error) {
      console.log("error.request.status", error.request.status);
      console.log("error.message", error.message);
      // console.log(error.request.response);
    }
  };
}

export function CloseDisputeAtLevelTwo(disputeId, closed_reason = null) {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "put",
        url: closed_reason
          ? `/closedisputeatleveltwo/${disputeId}/?host=${gethost()}&closed_reason=${closed_reason}`
          : `/closedisputeatleveltwo/${disputeId}/?host=${gethost()}`,
      });
      return response.data;
    } catch (error) {
      console.log("error.request.status", error.request.status);
      console.log("error.message", error.message);
      // console.log(error.request.response);
    }
  };
}

export const sendPaymentLinkRespondent = async (disputeId, closeStatus, selectedUserId) => {
  try {
    const response = await instance.post(`surepay/send_payment_link_respondent/`, {
      disputeId: disputeId,
      mii: "NSE",
      stage: "conciliation",
      close_status: closeStatus,
      user_id: selectedUserId, // Ensure this is set correctly
    });
    return response.data;
  } catch (error) {
    console.error("Error in sending payment link:", error);
  }
};

export function getDisputesForPagination(pageNo, disputesType, role) {
  let type = "";
  if (role == "claimant") {
    type = disputesType == "active" ? "openclaimant" : "closedclaimant";
  } else if (role == "respondent") {
    type = disputesType == "active" ? "openrespondent" : "closedrespondent";
  } else {
    type = disputesType == "active" ? "open" : "closed";
  }

  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: `/api/dispute/?page=${pageNo}&type=${type}` + "&host=" + gethost(),
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}
export function getDisputesHotstar(profileType) {
  console.log(profileType);
  let type =
    profileType == "vendor"
      ? "openrespondent"
      : profileType == "general"
      ? "openclaimant"
      : "open";
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: "/api/dispute/?type=" + type + "&host=" + gethost(),
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function getDisputescdr(profileType) {
  console.log(profileType);
  let type =
    profileType == "vendor"
      ? "globalcalendar"
      : profileType == "general"
      ? "globalcalendar"
      : "open";
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: "/api/dispute/?type=" + type + "&host=" + gethost(),
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}
export function getDisputesOpen(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: "/api/dispute/" + "?type=open&host=" + gethost(),
      params: params,
    }).then(
      (response) => {
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}
export function getDisputesOpen1(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: "/api/dispute/" + "?type=open&host=" + gethost(),
      params: params,
    }).then(
      (response) => {
        dispatch(setSucessfull(response.data));
        console.log("responseeeee", response.data.results);
        return response.data.results;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}
export function getDisputes(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: "/api/dispute/" + "?host=" + gethost(),
      params: params,
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}
export function getDisputesHotstarAdmin(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instanceHotstar({
      method: "get",
      url: "/api/dispute/",
      params: params,
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}
export function getDisputesDpcgcAdmin(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instanceDPCGC({
      method: "get",
      url: "/api/dispute/",
      params: params,
    }).then(
      (response) => {
        console.log("admindataaaaaaaa", response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function getDisputeAnonymous(id, email) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "get",
        url: `/api/disputeanonymous/`,
        params: {
          id: id,
          email: email,
        },
      });

      dispatch(setSucessfullGet(response.data.results[0]));

      return response.data;
    } catch (error) {
      console.log(error);
      // console.log(error.request.status);
      // console.log(error.message);
      // console.log(error.request.response);

      dispatch(setError());
    }
  };
}

export function getDispute(id) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "get",
        url: "/api/dispute/" + "?host=" + gethost(),
        params: {
          id: id,
        },
      });

      dispatch(setSucessfullGet(response.data.results[0]));

      return response.data;
    } catch (error) {
      console.log(error);
      // console.log(error.request.status);
      // console.log(error.message);
      // console.log(error.request.response);

      dispatch(setError());
    }
  };
}
export function getDPCGCDispute(id) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instanceDPCGC({
        method: "get",
        url: "/api/dispute/",
        params: {
          id: id,
        },
      });

      dispatch(setSucessfullGet(response.data.results[0]));

      return response.data;
    } catch (error) {
      console.log(error);
      // console.log(error.request.status);
      // console.log(error.message);
      // console.log(error.request.response);

      dispatch(setError());
    }
  };
}

export function createDisputeAnonymous(disputeBody) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/api/disputeanonymous/" + "?host=" + gethost(),
        data: disputeBody,
      });
      dispatch(setSucessfullCreate(response.data));

      return response.data.id;
    } catch (error) {
      console.log(error);
      dispatch(setError());
      throw error;
    }
  };
}

export function createDispute(disputeBody) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/api/dispute/" + "?host=" + gethost(),
        data: disputeBody,
      });
      dispatch(setSucessfullCreate(response.data));

      return response.data.id;
    } catch (error) {
      console.log(error);
      dispatch(setError());
      throw error;
    }
  };
}

export function updateDispute(disputePayload) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "patch",
        url: "/api/dispute/" + disputePayload.id + "/" + "?host=" + gethost(),
        data: disputePayload,
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}
export function updateDisputeHotstarAdmin(disputePayload) {
  return async (dispatch) => {
    try {
      let response = await instanceHotstar({
        method: "patch",
        url: "/api/dispute/" + disputePayload.id + "/",
        data: disputePayload,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}
export function updateDisputeDpcgcAdmin(disputePayload) {
  return async (dispatch) => {
    try {
      let response = await instanceDPCGC({
        method: "patch",
        url: "/api/dispute/" + disputePayload.id + "/",
        data: disputePayload,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}

export function approvedDispute(disputePayload) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "/approvedispute/" + disputePayload.id + "/?host=" + gethost(),
        data: disputePayload,
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}
export function closedDispute(disputePayload) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "/closedisputeatleveltwo/" + disputePayload.id + "/",
        data: disputePayload,
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}

export function uploadDispute(files, activeDispute) {
  return async (dispatch) => {
    dispatch(setProgress());
    const data = new FormData();
    files.forEach((file) => {
      data.append(file.name, file);
    });
    try {
      const response = await instance.put(
        "/uploaddispute/" + activeDispute.id + "/",
        data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch(setSucessfullGet(response.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function getUserFiles(disputeId) {
  return async (dispatch) => {
    // fetch files
    try {
      const response = await instance({
        method: "get",
        url: "/api/rvdocument/",
        params: {
          dispute__id: disputeId,
          host: gethost(),
        },
      });
      dispatch(setUserFiles(response.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function getUserFilesAnonymous(disputeId, email) {
  return async (dispatch) => {
    // fetch files
    try {
      const response = await instance({
        method: "get",
        url: "/api/anonymousrvdocument/",
        params: {
          dispute__id: disputeId,
          email: email,
          host: gethost(),
        },
      });
      dispatch(setUserFiles(response.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function addUserFileAnonymous(fileBody, email) {
  return async (dispatch) => {
    // put file to backend
    const data = new FormData();
    Object.keys(fileBody).forEach((key) => {
      data.append(key, fileBody[key]);
    });
    console.log(data);
    try {
      const response = await instance({
        method: "post",
        url: "/api/anonymousrvdocument/" + "?host=" + gethost(),
        data: data,
        params: {
          email: email,
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      await dispatch(getUserFilesAnonymous(fileBody.dispute, email));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function deleteUserFileAnonymous(id, disputeId, email) {
  return async (dispatch) => {
    // delete file using id
    try {
      const response = await instance({
        method: "delete",
        url: `/api/anonymousrvdocument/${id}/`,
        params: {
          email: email,
        },
      });
      await dispatch(getUserFilesAnonymous(disputeId, email));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function addUserFile(fileBody) {
  return async (dispatch) => {
    // put file to backend
    const data = new FormData();
    Object.keys(fileBody).forEach((key) => {
      data.append(key, fileBody[key]);
    });
    console.log(data);
    try {
      const response = await instance({
        method: "post",
        url: "/api/rvdocument/" + "?host=" + gethost(),
        data: data,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      await dispatch(getUserFiles(fileBody.dispute));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function deleteUserFile(id, disputeId) {
  return async (dispatch) => {
    // delete file using id
    try {
      const response = await instance({
        method: "delete",
        url: `/api/rvdocument/${id}/`,
      });
      await dispatch(getUserFiles(disputeId));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function renameUserFile(id, disputeId, newPath) {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "patch",
        url: `/api/rvdocument/${id}/`,
        data: { id: id, path: newPath },
      });
      await dispatch(getUserFiles(disputeId));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export async function downloadFile(id) {
  try {
    const response = await instance({
      method: "get",
      url: `/api/rvdocurl/${id}/`,
    });

    // window.open(response.data.url, "_blank");
    window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}
export async function downloadFileAdminHotstar(id) {
  try {
    const response = await instanceHotstar({
      method: "get",
      url: `/api/rvdocurl/${id}/`,
    });

    // window.open(response.data.url, "_blank");
    window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}
export async function downloadFileAdminDpcgc(id) {
  try {
    const response = await instanceDPCGC({
      method: "get",
      url: `/api/rvdocurl/${id}/`,
    });

    // window.open(response.data.url, "_blank");
    window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadLegalDoc(id) {
  try {
    const response = await instance({
      method: "get",
      url: `/api/legaldocurl/${id}/`,
    });

    // window.open(response.data.url, "_blank");
    window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadRecording(id) {
  try {
    const response = await instance({
      method: "get",
      url: `/api/recordingurl/${id}/`,
    });
    console.log("response.data: ", response.data);
    window.open(response.data.url, "_blank");
    // window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}

export const getRecordings = (disputeId) => {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "get",
        url: "/api/recordings/" + disputeId + "/",
      });
      // console.log("dfghj: ", response.data.data)
      dispatch(setRecordings(response.data.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
};
export const getDPCGCRecordings = (disputeId) => {
  return async (dispatch) => {
    try {
      const response = await instanceDPCGC({
        method: "get",
        url: "/api/recordings/" + disputeId + "/",
      });
      // console.log("dfghj: ", response.data.data)
      dispatch(setRecordings(response.data.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
};

export const getDisputesCount = () => {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "get",
        url: "/api/countdisputes/" + "?host=" + gethost(),
      });
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
};

// export function ForewardDisputeHotstar(disputePayload) {
//   console.log("disputeId fun",disputePayload.id );
//   // let id  = disputePayload.id
//   return async (dispatch) => {
//     try {
//       let response = await instance({
//         method: "put",
//         url: `/api/ForwardDisputeHotstarView/${disputePayload.id}/`,
//         // data: disputePayload,
//       });
//       console.log("response.data", response.data);
//       return response.data;

//       // dispatch(setSucessfull(response.data));
//     } catch (error) {
//       console.log("error.request.status", error.request.status);
//       console.log("error.message", error.message);
//       // console.log(error.request.response);
//     }
//   };
// }
export function ForwardDisputeHotstar(disputePayload) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        // url: "/approvedispute/" + disputePayload.id + "/",
        url: `/api/ForwardDisputeHotstarView/${disputePayload.id}/`,
        data: disputePayload,
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}

export function addRvFileUpload(fileBody, id, award_type) {
  return async (dispatch) => {
    // put file to backend
    const data = new FormData();
    Object.keys(fileBody).forEach((key) => {
      data.append(key, fileBody[key]);
    });
    console.log("dataaaaaaaa", data);
    try {
      const response = await instance({
        method: "post",
        url: "/tiacupload/" + id + "/?award_type=" + award_type,
        data: data,
        // params: {
        // email: email,
        // },
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      await dispatch(addRvFileUpload(fileBody.dispute.id));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function tiacdoc(dispute_id, id) {
  let f = "Terms of Reference and Procedural Order_" + id + ".docx";
  instance({
    method: "get",
    url: "/tiacgeneratedoc1/" + dispute_id,
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, f);
  });
}

export function generateDifcAgreement(dispute_id) {
  let file_name = `Mediation_Agreement_${dispute_id}.docx`;
  instance({
    method: "get",
    url: `/difc_generate_agreement/${dispute_id}/`,
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, file_name);
  });
}

export function getDisputesForPaginationHotstar(pageNo, disputesType) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url:
        `/api/dispute/?page=${pageNo}&type=${disputesType}` +
        "&host=" +
        gethost(),
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function getDisputesForStar(
  pageNo,
  disputesType,
  start_date,
  claimant_name
) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url:
        `/disputelist/?dispute_type=${disputesType}&page=${pageNo}` +
        "&host=" +
        gethost(),
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export const getStarChannelsName = () => {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "get",
        url: "/star/channels/",
      });
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
};

export const getStarViolationCategory = () => {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "get",
        url: "/tags/",
      });
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
};

export const removeOrAddTagFromDispute = (disputeId, tagId, type) => {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "get",
        url: `/tags/change/${disputeId}/${tagId}/${type}`,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
};

export function bulkResponseStar(disputeBody) {
  //   const disputeBody = {
  //     "disputes" : [6430, 6428],
  //     "answer" : "Hi there"
  // }
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "post",
        url: "/star/bulk_response/",
        data: disputeBody,
      });

      return response.data.id;
    } catch (error) {
      console.log(error);
      dispatch(setError());
      throw error;
    }
  };
}

export function getDisputesForPaginationMuthoot(
  pageNo,
  disputesType,
  region,
  days
) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url:
        `/api/dispute/?page=${pageNo}&type=${disputesType}` +
        "&host=" +
        gethost() +
        "&region=" +
        region +
        "&days=" +
        days,
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function updateMuthootStatus(disputeId, disputePayload) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "update-muthoot-status/" + disputeId + "/",
        data: disputePayload,
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}

export function MuthootCaseResolved(disputeId) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "api/muthoot-case-resolve/" + disputeId + "/",
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}

export function getDisputesForPaginationOrix(
  pageNo,
  disputesType,
  region,
  days
) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url:
        `/api/dispute/?page=${pageNo}&type=${disputesType}` +
        "&host=" +
        gethost() +
        "&region=" +
        region +
        "&days=" +
        days,
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function orixCaseResolved(disputeId) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "api/orix-case-resolve/" + disputeId + "/",
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}

export function reopenOrixCase(disputeId) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "api/orix-reopen-dispute/" + disputeId + "/",
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}
