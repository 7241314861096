import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

const UploadDetails = ({ uploadDetails }) => {
    if (!uploadDetails) {
        return (
          <Box mt={4}>
            <Typography variant="body1" color="textSecondary">
              No upload details available.
            </Typography>
          </Box>
        );
    }
    return (
        <Box mt={4}>
        <Typography variant="h6" gutterBottom>
            Upload Details
        </Typography>
        {uploadDetails?.total_created_users_and_disputes ? 
            <Typography>
                {uploadDetails?.total_created_users_and_disputes}
            </Typography>
         : null}
        {/* Created Users Section */}
        <Typography variant="h6" gutterBottom>
            Created Users
        </Typography>
        {uploadDetails.createdUsers && uploadDetails.createdUsers.length > 0 ? (
            <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell><strong>Email</strong></TableCell>
                    <TableCell><strong>Profile ID</strong></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {uploadDetails.createdUsers.map((user, index) => (
                    <TableRow key={index}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.profile_id}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        ) : (
            <Typography variant="body1" sx={{ mb: 4 }}>
            No users were created.
            </Typography>
        )}

        {/* Errors Section */}
        <Typography variant="h6" gutterBottom color="error">
            Errors
        </Typography>
        {uploadDetails?.user_creation_errors && uploadDetails?.user_creation_errors?.length > 0 ? (
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell><strong>Email</strong></TableCell>
                    <TableCell><strong>Error Message</strong></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {uploadDetails?.user_creation_errors?.map((error, index) => (
                    <TableRow key={index}>
                    <TableCell>{error.email}</TableCell>
                    <TableCell>
                        {Object.entries(error.error_detail).map(([field, messages]) => (
                            <div key={field}>
                                <strong>{field}:</strong> {messages.join(", ")}
                            </div>
                        ))}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        ) : (
            <Typography variant="body1" color="error">
            No errors were found.
            </Typography>
        )}
        </Box>
    );
};

export default UploadDetails;
