import React from 'react';
import { Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateRangeOutlined } from '@material-ui/icons';

export default function DateRangePicker({startDate, setStartDate, endDate, setEndDate, minimumStartDate=new Date('1900-01-01'), maximumStartDate=new Date('2100-12-31'), minimumEndDate=new Date('1900-01-01'), maximumEndDate=new Date('2100-12-31')}) {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box style={{display:'flex',gap:'15px',flex:1}}>
        <DatePicker
          views={['year','month','date']}
          inputVariant='outlined'
          size={'small'}
          fullWidth
          label='From'
          placeholder='DD/MM/YYYY'
          format="dd/MM/yyyy"
          value={startDate}
          onChange={setStartDate}
          InputProps={{
            endAdornment: <DateRangeOutlined style={{color:'#858D98',cursor:'pointer'}}/>
          }}
          clearable={true}
          clearLabel={'Clear'}
          minDate={minimumStartDate}
          maxDate={maximumStartDate}
        />
        <DatePicker
          views={['year','month','date']}
          inputVariant='outlined'
          size={'small'}
          fullWidth
          label='To'
          placeholder='DD/MM/YYYY'
          format="dd/MM/yyyy"
          value={endDate}
          onChange={setEndDate}
          InputProps={{
            endAdornment: <DateRangeOutlined style={{color:'#858D98',cursor:'pointer'}}/>
          }}
          clearable={true}
          clearLabel={'Clear'}
          minDate={minimumEndDate}
          maxDate={maximumEndDate}
        />    
      </Box>
    </MuiPickersUtilsProvider>
  )
}