import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Alert } from "@material-ui/lab";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { isHotStar, isMuthoot, isOrix, isRv, isStar } from "Routes/Routes";
import { logout, sendOtpAsync, setTokenAsync } from "slices/loginSlice";
import { getCitiesByStateName, register } from "slices/userSlice";
import * as Yup from "yup";
import "../signup/otp.css";
import { INDIAN_STATES } from "app/constants";

var signUpData = {};
var popUpBox = false;
var otpServerResponse = "";
var t1 = null;
var t2 = null;
var timerRunning = false;
var temp = 0;

const OtpButton = () => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const closeModal = () => {
    setOpen(false);
    temp = 0;
  };
  let history = useHistory();
  const [mobileOtp, setMobileOtp] = useState("");

  const otpSubmit = async () => {
    if (otpServerResponse && otp) {
      let email = signUpData.email;
      let phoneNumber =
        signUpData.phoneNumber.trim() == "" ? null : signUpData.phoneNumber;
      let password = signUpData.password;
      // let birthDate = signUpData.birthDate;
      let gender = signUpData.gender;
      let firstName = signUpData.firstName;
      let lastName = signUpData.lastName;
      let city = signUpData?.city;
      let state = signUpData?.state;

      let profile_type = isRv ? "general_rv" : "general";

      function handleSignupResponse(signUpResponse) {
        if (signUpResponse.id) {
        } else {
          if (signUpResponse.user && signUpResponse.phone_number) {
            alert(
              signUpResponse.user.email[0] +
                "\n and \n" +
                signUpResponse.phone_number[0]
            );
            setOpen(false);
          } else if (signUpResponse.user) {
            alert(signUpResponse.user.email[0]);
            setOpen(false);
          } else if (signUpResponse.phone_number) {
            alert(signUpResponse.phone_number[0]);
            setOpen(false);
          } else if (signUpResponse.data.error) {
            alert(signUpResponse.data.error);
          } else {
            setOpen(false);
          }
        }
      }

      let signUpResponse = await dispatch(
        register({
          email,
          phoneNumber,
          profile_type,
          password,
          // birthDate: format(birthDate, "yyyy-MM-dd"),
          gender,
          firstName,
          lastName,
          otp,
          city,
          state,
          mobileOtp,
        })
      );
      handleSignupResponse(signUpResponse);
      await dispatch(setTokenAsync(signUpData.email, signUpData.password));
      history.push("/disputes");
      popUpBox = false;
    } else {
      alert("empty otp!!");
    }
  };

  class Timer extends React.Component {
    state = {
      minutes: 4,
      seconds: 59,
    };

    constructor(props) {
      super(props);
      this.sendOTPAgain = this.sendOTPAgain.bind(this);
      t2 = new Date();
      var dif = t2 - t1;
      var Seconds_from_T1_to_T2 = parseInt(dif / 1000);
      this.state.minutes =
        this.state.minutes - parseInt(Seconds_from_T1_to_T2 / 60);
      this.state.seconds =
        this.state.seconds - parseInt(Seconds_from_T1_to_T2 % 60);

      if (this.state.minutes < 0 || this.state.seconds < 0) {
        this.state.minutes = 0;
        this.state.seconds = 0;
      }
    }
    componentDidMount() {
      this.myInterval = setInterval(() => {
        const { minutes, seconds } = this.state;
        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1,
          }));
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(this.myInterval);
            timerRunning = false;
            if (temp == 0) {
              this.componentDidMount();
              temp++;
              setOtp("0");
              setOtp(otp);
            }
          } else {
            this.setState(({ minutes }) => ({
              minutes: minutes - 1,
              seconds: 59,
            }));
          }
        }
      }, 1000);
    }
    componentWillUnmount() {
      clearInterval(this.myInterval);
    }

    sendOTPAgain = async () => {
      let email = signUpData.email;
      let firstName = signUpData.firstName;
      let phoneNumber = signUpData.phoneNumber;
      otpServerResponse = await dispatch(
        sendOtpAsync(email, firstName, phoneNumber)
      );

      t1 = new Date();
      this.state.minutes = 4;
      this.state.seconds = 59;
      timerRunning = true;

      this.componentDidMount();
      temp = 0;
      setOtp("");
      setMobileOtp("");
    };

    render() {
      const { minutes, seconds } = this.state;
      return (
        <div className="timer" style={{ color: "red" }}>
          {minutes === 0 && seconds === 0 ? (
            <br />
          ) : (
            <p>
              Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </p>
          )}
          <div
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Button
              style={{
                width: "60%",
                maxWidth: "30rem",
                height: "2.7rem",
                right: "0.3rem",
              }}
              className={`proceedButton ${(isHotStar || isStar || isMuthoot) && classes.hoverButton}`}
              variant="contained"
              color="primary"
              onClick={otpSubmit}
            >
              {"Signup"}
            </Button>
            <Button
              style={{
                width: "40%",
                maxWidth: "25rem",
                height: "2.7rem",
                left: "0.3rem",
              }}
              variant="contained"
              color="primary"
              className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
              onClick={this.sendOTPAgain}
              disabled={timerRunning}
            >
              {"Resend OTP"}
            </Button>
          </div>
        </div>
      );
    }
  }

  const closeModal2 = () => {
    popUpBox = false;
    setOtp("");
    temp = 0;
  };

  return (
    <div>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
      >
        {"OTP"}
      </Button>
      <Popup
        open={popUpBox && open}
        // trigger={}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModal2}
        className="my-popup"
        position="center"
        modal
        nested
        contentStyle={{ textAlign: "center", background: "white" }}
        overlayStyle={{ background: "" }}
      >
        <div className="modal">
          <button
            type="button"
            className="close"
            onClick={closeModal}
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 className="font-related">
            One Time Password (OTP) has been sent to your registered Email ID
            and Phone Number
            <TextField
              className="textf"
              variant="filled"
              margin="normal"
              value={otp}
              required
              fullWidth
              autoFocus
              placeholder="One Time Password (OTP)*"
              type="number"
              onChange={(e) => setOtp(e.target.value)}
              label={isOrix ? "Email OTP" : ""}
            />
            {isOrix && (
              <TextField
                className="textf"
                variant="filled"
                margin="normal"
                value={mobileOtp}
                required
                fullWidth
                autoFocus
                placeholder="One Time Password (OTP)*"
                type="number"
                onChange={(e) => setMobileOtp(e.target.value)}
                label={"Phone OTP"}
              />
            )}
            <Timer></Timer>
            <br />
          </h4>
        </div>
      </Popup>
    </div>
  );
};

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Invalid phone number")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    // .min(6, "Password Too Short!")
    // .max(20, "Password Too Long!")
    .min(6, "Password Too Short! Minimum Length - 6")
    .max(15, "Password Too Long! Maximum Length - 15")
    .matches(/^(.*[A-Z].*)/, "Must Contain an Upper Case Alphabet")
    .matches(/^(.*[a-z].*)/, "Must Contain a Lower Case Alphabet")
    .matches(/^(.*[0-9].*)/, "Must Contain a number")
    .matches(/^(.*\W.*)/, "Must Contain a special character")
    .required("Password Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
  gender: Yup.string()
    .oneOf(["male", "female"], "")
    .required("Please select your gender"),
  // state: Yup.string().required("State is required"),
  // city: Yup.string().required("City is required"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

export default () => {
  const userErrors = useSelector((state) => state.user.errors);
  const hasErrors = useSelector((state) => state.user.hasErrors);

  // const [phoneError, setPhoneError] = useState();

  // const onPhoneChange = (e) => {
  //   setPhoneError();
  //   validatePhone(e.target.value);
  //   formik.setFieldValue("phoneNumber", e.target.value);
  // };

  // const validatePhone = (v) => {
  //   let ph = v;
  //   if (ph.trim() != "" && ph.length != 10) {
  //     setPhoneError("Invalid phone number");
  //   }
  // };

  const classes = useStyles();
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);

  useEffect(() => {
    dispatch(logout());
  }, []);

  function handleSignupResponse(signUpResponse) {
    if (signUpResponse.id) {
    } else {
      if (signUpResponse.user && signUpResponse.phone_number) {
        alert(
          signUpResponse.user.email[0] +
            "\n and \n" +
            signUpResponse.phone_number[0]
        );
      } else if (signUpResponse.user) {
        alert(signUpResponse.user.email[0]);
      } else if (signUpResponse.phone_number) {
        alert(signUpResponse.phone_number[0]);
      } else if (signUpResponse.data.error) {
        alert(signUpResponse.data.error);
      } else {
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      gender: "male",
      state: "",
      city: "",
      // birthDate: Date("yyyy-MM-dd"),
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      let {
        email,
        phoneNumber,
        password,
        // birthDate,
        gender,
        firstName,
        lastName,
      } = values;

      signUpData = values;

      if (!isEmpty(signUpData)) {
        otpServerResponse = await dispatch(
          sendOtpAsync(email, firstName, phoneNumber)
        );
        if (!otpServerResponse?.success) {
          handleSignupResponse(otpServerResponse);
        } else {
          popUpBox = true;
          t1 = new Date();
          timerRunning = true;
        }
      }
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <FormattedMessage id="auth.signup" />
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            value={formik.email}
            required
            fullWidth
            id="email"
            label={<FormattedMessage id="user.email" />}
            name="email"
            autoComplete="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.email && formik.touched.email && (
            <Alert severity="error">{formik.errors.email}</Alert>
          )}
          {Object.keys(formik.errors).length == 0 &&
            hasErrors &&
            userErrors.email[0] &&
            formik.touched.email && (
              <Alert severity="error">{userErrors.email[0]}</Alert>
            )}
          <TextField
            required={isMuthoot || isOrix}
            variant="outlined"
            margin="normal"
            fullWidth
            name="phoneNumber"
            label={<FormattedMessage id="user.phoneNumber" />}
            // type="number"
            id="phoneNumber"
            value={formik.values.phoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.phoneNumber && (
            <Alert severity="error">{formik.errors.phoneNumber}</Alert>
          )}
          {/* {phoneError && <Alert severity="error">{phoneError}</Alert>} */}
          <TextField
            variant="outlined"
            margin="normal"
            value={formik.firstName}
            required
            fullWidth
            id="firstName"
            label={<FormattedMessage id="user.firstName" />}
            name="firstName"
            autoComplete="firstName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <Alert severity="error">{formik.errors.firstName}</Alert>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            value={formik.lastName}
            required
            fullWidth
            id="lastName"
            label={<FormattedMessage id="user.lastName" />}
            name="lastName"
            autoComplete="lastName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <Alert severity="error">{formik.errors.lastName}</Alert>
          )}
          {isOrix && (
            <>
              <TextField
                required
                fullWidth
                variant='outlined'
                margin="normal"
                select
                label='State'
                name="state"
                value={formik.state}
                onChange={(e) => {
                  formik.handleChange(e);
                  setCities([]);
                  formik.setFieldValue("city", "");
                  getCitiesByStateName(e.target.value).then(
                    (response) => {
                      setCities(response);
                    }
                  );
                }}
              >
                {INDIAN_STATES.map((state, index) => (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
              {formik.errors.state && formik.touched.state && (
                <Alert severity="error">{formik.errors.state}</Alert>
              )}
              <TextField
                required
                fullWidth
                variant='outlined'
                margin="normal"
                select
                label='City'
                name="city"
                value={formik.city}
                onChange={formik.handleChange}
                disabled={cities?.length === 0}
              >
                {cities?.map((city, index) => (
                  <MenuItem key={index} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
              {formik.errors.city && formik.touched.city && (
                <Alert severity="error">{formik.errors.city}</Alert>
              )}
            </>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={<FormattedMessage id="auth.password" />}
            type={passwordType}
            id="password"
            value={formik.values.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType == "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password && (
            <Alert severity="error">{formik.errors.password}</Alert>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label={<FormattedMessage id="auth.confirmPassword" />}
            type={passwordType}
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType == "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <Alert severity="error">{formik.errors.confirmPassword}</Alert>
          )}

          {/* <RadioGroup
            aria-label="gender"
            id="gender"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
          >
            <Typography variant="subtitle1">Gender</Typography>
            <Box display="flex">
              <FormControlLabel
                value="female"
                control={<Radio color="primary" />}
                label="Female"
              />
              <Box width="2rem"></Box>
              <FormControlLabel
                value="male"
                control={<Radio color="primary" />}
                label="Male"
              />
            </Box>
          </RadioGroup>
          {formik.errors.gender && formik.touched.gender && (
            <Alert severity="error">{formik.errors.gender}</Alert>
          )} */}
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="birthDate"
                            name="birthDate"
                            label={<FormattedMessage id="user.birthdate" />}
                            format="dd/MM/yyyy"
                            data-test="birthDate"
                            value={formik.values.birthDate}
                            onChange={(value) => {
                                const event = {
                                    persist: () => { },
                                    target: {
                                        type: "change",
                                        name: "birthDate",
                                        value,
                                    },
                                };
                                formik.handleChange(event);
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    {formik.errors.birthDate && (
                        <Alert severity="error">{formik.errors.birthDate}</Alert>
                    )} */}
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {<FormattedMessage id="auth.signup" />}
          </Button> */}

          <br />
          <Box height="2rem"></Box>
          <OtpButton></OtpButton>
        </form>
      </div>
      <Box mt={8}>{/* <Copyright /> */}</Box>
      <Box height="2rem"></Box>
    </Container>
  );
};
