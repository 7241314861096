import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  makeStyles,
  Link, 
  Paper,
  CircularProgress
} from '@material-ui/core';
import {CloudUploadOutlined } from '@material-ui/icons';
import instance from 'api/globalaxios';
import { useSnackbar } from 'contexts/SnackbarContext';
import UploadDetails from './UploadDetails';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '100px',
    alignItems: 'center',
    minHeight: '100vh',
    gap: 20,
  },
  layout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '60%',
  },
  selectBox: {
    width: '45%',
  },
  uploadBox: {
    width: '45%',
  },
  bottomButton: {
    marginTop: 40,
  },
  headingBulk: {
    marginBottom: 40
  },
  infoSection: {
    textAlign: 'center',
    marginTop: 20,
  },
  sampleFileLink: {
    marginTop: 10,
    display: 'block',
  },
});
const BulkCaseCreation = () => {
  const classes = useStyles();
  const [clientsEmails, setClientsEmails] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [file, setFile] = useState(null);
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [uploadDetails, setUploadDetails] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await instance.get("api/bulk-user-dispute-case/");
        setClientsEmails(response.data.clients_emails);
      } catch (error) {
        showSnackbar('Error fetching client emails:', 'error');
      }
    };

    fetchClients();
  }, []);

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();
      if (!['xls', 'xlsx'].includes(fileExtension)) {
        showSnackbar('Only Excel files (.xls, .xlsx) are allowed.', 'error');
        return;
      }
      setFile(uploadedFile);
    }
  };
  
  const handleCreateCase = async () => {
    if (!selectedClient) {
      showSnackbar('Please select a client before creating a case.', 'error');
      return;
    }
    if (!file) {
      showSnackbar('Please upload a valid Excel file.', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('excel_file', file);
    formData.append('claimant_email', selectedClient);

    setLoading(true);
    setUploadDetails(null);

    try {
      const response = await instance.post('api/bulk-user-dispute-case/', formData);
      const data = response.data;

      if (data.errors) {
        setUploadDetails({ createdUsers: data.created_users, errors: data.errors, user_creation_errors: data.user_creation_errors })
        showSnackbar(data?.message, 'warning');
      } else {
        setUploadDetails({ createdUsers: data.created_users, errors: null });
        showSnackbar('Case created successfully!', 'success');
      }
    } catch (error) {
      if (error.response && Array.isArray(error.response.data)) {
        const missingColumns = error.response.data.join(', ');
        showSnackbar(`Upload failed: Missing required columns: ${missingColumns}`, 'error');
      } else {
        showSnackbar('Failed to create case. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper className={classes.container}>
      <Typography variant="h4" gutterBotto className={classes.headingBulk}>
        Case Management
      </Typography>
      <Box className={classes.layout}>
        <FormControl className={classes.selectBox}>
          <InputLabel id="client-select-label">Select Client</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={selectedClient}
              onChange={handleClientChange}
            >
              {clientsEmails?.map((email) => (
                <MenuItem key={email} value={email}>
                  {email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

            {!file ? (
              <Button
                className={classes.uploadBox}
                variant="contained"
                component="label"
                startIcon={<CloudUploadOutlined />}
              >
                Uplod File
                <input
                  type="file"
                  hidden  
                  accept=".xls, .xlsx"
                  onChange={handleFileChange}
                />
              </Button>
            ) : (
              <Box display="flex" alignItems="center" className={classes.uploadBox}>
                <Typography variant="body1" style={{ marginRight: '10px' }}>
                  {file.name}
                </Typography>
                <Button
                  onClick={() => setFile(null)}
                  variant="text"
                  color="secondary"
                  size="small"
                >
                  Delete
                </Button>
              </Box>
            )}
      </Box>

      <Typography className={classes.infoSection} variant="body1">
        Only Excel files (.xls, .xlsx) are allowed. You can download a sample file below to check the format.
      </Typography>
      <Link
        href="CloudUploadOutlined"
        download
        className={classes.sampleFileLink}
      >
        Download Sample Excel File
      </Link>


      {loading ? (
        <CircularProgress />
      ) : (
        <Button
          className={classes.bottomButton}
          variant="contained"
          color="primary"
          onClick={handleCreateCase}
        >
          Create Case
        </Button>
      )}

     <UploadDetails uploadDetails={uploadDetails}/>
    </Paper>
  );
};

export default BulkCaseCreation;
