import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
}));

const TableContainer = ({ children, className, customStyles }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`} style={customStyles}>
      {children}
    </div>
  );
};

TableContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  customStyles: PropTypes.object,
};

export default TableContainer;
