import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  makeStyles,
  Paper,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import instance from "api/globalaxios";
import PaymentForm from "./PaymentForm";
import { useLocation, useHistory } from "react-router-dom";
import querystring from "query-string";
import { useSnackbar } from "contexts/SnackbarContext";

export async function DownloadInvoice(id, showSnackbar) {
  try {
    const response = await instance.get(`/surepay/download_invoice/${id}/`);
    window.location.href = response.data.url;
    showSnackbar("Invoice is downloaded", "info");
  } catch (error) {
    console.error("Error downloading invoice:", error);
  }
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: "100%",
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    background: "#2F80ED",
    color: "white",
    textTransform: "none",
  },
  downloadBtn: {
    marginTop: theme.spacing(2),
    background: "#2F80ED",
    color: "white",
    textTransform: "none",
    marginRight: "10px",
  },
  paper2: {
    padding: "1.5rem 3rem",
    borderRadius: "8px",
  },
  disclaimer: {
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "400",
    color: "#252733",
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

const SebiPaymentFormNew = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const {
    disputeId,
    description,
    userid,
    amount,
    stage,
    mii,
    payment_link_obj_id,
    gst,
    address
  } = querystring.parse(location.search);
  const [paymentData, setPaymentData] = useState(null);
  // const [entityName, setEntityName] = useState("");
  // const [address, setAddress] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const [transactionState, setTransactionState] = useState({
    data: null,
    error: null,
  });

  const handleDownloadInvoice = async (e) => {
    // if (isEmpty(entityName) || isEmpty(address)) {
    //   alert("Please fill necessary fields");
    //   return;
    // }
    // e.preventDefault();
    setLoading(true);

    let requestData = {
      amount,
      userid,
      currrency: "INR",
      description,
      disputeId,
      mii, // Use the id property of the selected MII
      entity_name: gst,
      address,
      gst_number: gstNumber,
      stage,
      download_invoice: "yes",
      payment_link_obj_id,
    };

    try {
      const response = await initiateTransactionOfSebiAnonymous(requestData);
      // await DownloadInvoice(response?.data?.id, showSnackbar);
      setTimeout(async () => {
        requestData["download_invoice"] = "no";
        const response2 = await initiateTransactionOfSebiAnonymous(requestData);
        setPaymentData(response2.data);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error submitting data:", error);
      setLoading(false);
    }
  };

  // Function to get transaction
  const fetchTransaction = async () => {
    try {
      const res = await getTransaction(payment_link_obj_id);
      setTransactionState({ data: res.data, error: null });
    //   setEntityName(res?.data?.entity_name);
      setGstNumber(res?.data?.gst_number)
    //   setAddress(res?.data?.address);
    } catch (err) {
      setTransactionState({ data: null, error: err?.response?.data?.error });
      console.log("Error in Transaction:", err);
    }
  };

  useEffect(() => {
    getPaymentLinkObj(payment_link_obj_id).then((res) => {
      // verify details
      if (
        !(
          !res?.data?.disabled &&
          res?.data?.amount === amount &&
          res?.data?.disputeId === disputeId &&
          res?.data?.userid === userid
        )
      ) {
        history.push("/sebi-payment-invalid-link");
      }
    });
    // fetchInvoice();
    fetchTransaction();
    handleDownloadInvoice()
  }, [payment_link_obj_id]);

  return (
    <>
      {paymentData ? (
        <PaymentForm responseFromApi={paymentData} />
      ) : (
        <Grid container justify="center" alignItems="center" style={{ height: "100vh" }}>
            <Grid item>
                <CircularProgress />
                <Typography variant="h6" style={{ marginTop: "1rem" }}>Processing payment...</Typography>
            </Grid>
        </Grid>
      )}
    </>
  );
};

async function initiateTransactionOfSebiAnonymous(data) {
  return await instance.post("/surepay/initiate-transaction/", data, {
    headers: {
      "content-type": "application/json",
    },
  });
}

export async function getPaymentLinkObj(id) {
  return await instance.get(`/surepay/payment-links/${id}/`);
}

async function getInvoice(id) {
  return await instance.get(`/surepay/get_invoice/${id}/`);
}

async function getTransaction(id) {
  return await instance.get(`/surepay/get_transaction/${id}/`);
}

export default SebiPaymentFormNew;